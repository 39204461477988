//customSwitch
.customSwitch {
  .MuiSwitch-root {
    height: 40px;
    width: 55px;
    .MuiButtonBase-root {
      top: 9.5px;
      left: 10px;
      .MuiSwitch-thumb {
        height: 9px;
        min-height: 9px;
        min-width: 9px;
        width: 9px;
        box-shadow: none;
        background-color: $switchDisabledBorderColor;
        border-color: $switchDisabledBorderColor;
      }
      &.Mui-checked {
        transform: translateX(24px);
        left: 0px !important;
      }
      &.Mui-checked .MuiSwitch-thumb {
        background-color: $primary !important  ;
        border: 1px solid $primary !important;
      }
      &.Mui-checked + .MuiSwitch-track {
        background-color: transparent;
        border: 1px solid $primary;
        opacity: 9;
        @include border-radius(11px);
      }
    }
    .MuiSwitch-track {
      background-color: transparent;
      border: 1px solid #9a9a9a;
      @include border-radius(11px);
    }
  }
}
