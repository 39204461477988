.large {
  display: inline-block;
}
.small {
  display: none;
}
.SidebarWrapper {
  position: relative;
  z-index: 7;
  height: 100%;
  padding-bottom: 68px;
  background-color: $white !important;
  .SubMenuWrapper {
    .MuiButton-root {
      position: relative;
      &.active,
      &:hover {
        &::before {
          content: '';
          position: absolute;
          width: 4px;
          height: 100%;
          border-radius: 0px 99px 99px 0px;
          background-color: $primary;
          left: 0;
        }
      }
    }
  }
}
