@import './variables';
@import './mixins';
@import './fontStyle.scss';
@import './component/app';
@import './component/login';
@import './component/header';
@import './component/notifications';
@import './component/userbox';
@import './component/layout';
@import './component/suspenseloader';
@import './component/sidebar';
@import './component/app';
@import './component/table';
@import './component/modal';
@import './component/bot_modal';
@import './component/tabs';
@import './component/statusChips';
@import './component/switch';
@import './component/profilePicture';
@import './component/datePicker';
@import './component/deprecated_calender';
@import './component/calender';
@import './component/inputField';
@import './component/rating';

//common classes goes here
body {
  overflow-y: scroll !important;
  padding: 0 !important;
  background-color: #f4f6fc;
}

.cursorPointer {
  cursor: pointer;
}

.displayNone {
  display: none !important;
}

.cursorDefault {
  cursor: default !important;
}

.hr-line {
  border: 1px solid $borderColor;
}

.p-absolute {
  position: absolute;
}

.p-relative {
  position: relative;
}

.p-fixed {
  position: fixed;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//multiple file upload icon size

.text-center {
  text-align: center;
}

//Text Transform
.textUppercase {
  text-transform: uppercase !important;
}

.textCapitalize {
  text-transform: capitalize !important;
}

.textNone {
  text-transform: none !important;
}

//text align
.flex-basic-center {
  @include flex-basic-center;
}

.flex-column-center {
  @include flex-column-center;
}

.justify-content-center {
  justify-content: center;
}

.flex-basic-start {
  justify-content: start !important;
  @include flex-basic-center;
}

.flex-basic-space-between {
  justify-content: space-between !important;
  @include flex-basic-center;
}

.flex-basic-space-around {
  justify-content: space-around !important;
  @include flex-basic-center;
}

.flex-basic-end {
  justify-content: end !important;
  @include flex-basic-center;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-column-end {
  display: flex;
  flex-direction: column !important;
  align-items: flex-end;
}

.flex-column-start {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: start;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.active-tab-option-box {
  width: min-content;
}

.active-tab-box {
  width: max-content;
}

.active-in-last,
.active-in-last * {
  box-sizing: border-box;
}

.active-in-last {
  color: #3e3939;
  text-align: left;
  font-family: 'Poppins-Medium', sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  position: relative;
}

.active-in-last-menu,
.active-in-last-menu * {
  box-sizing: border-box;
}

.active-in-last-menu {
  background: #ffffff;
  border-radius: 6px;
  border-style: solid;
  border-color: rgba(46, 91, 255, 0.14);
  border-width: 1px;
  padding: 6px 12px 6px 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
}
.dashboard-active-menu {
  background: #ffffff;
  border-bottom: 1px solid rgba(46, 91, 255, 0.14);
  padding: 6px 12px 6px 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
}
.dashboard-internal-external-menu {
  background: #ffffff;
  border-radius: 8px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.14);
  border-width: 1px;
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
}

.active-in-last-option-box {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}

.last-active-option {
  color: #444655;
  text-align: left;
  font-family: 'Poppins-Medium', sans-serif;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  position: relative;
}
.dashboard-active-option {
  color: $dashboardTextColor;
  text-align: left;
  font-family: 'Poppins-Medium', sans-serif;
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
  position: relative;
}
.dashboard-internal-external-option-box {
  color: $grayColor;
  text-align: left;
  font-family: 'Poppins-Medium', sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  position: relative;
}

.chevron-down {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}

.align-items-start {
  align-items: start !important;
}

.align-items-end {
  align-items: end !important;
}

.textDecorationNone {
  text-decoration: none !important;
}

.font-weight-bold {
  //font weight 700
  @include font-weight-bold();
}

.font-weight-semibold {
  //font weight 600
  @include font-weight-semibold();
}

.font-weight-medium {
  //font weight 500
  @include font-weight-medium();
}

.font-weight-regular {
  //font weight 400
  @include font-weight-regular();
}

/******* Typography *******/

.text-h1 {
  //font size 32px
  font: normal 500 2rem/36px $font-family-base;
}

.text-h2 {
  //font size 28px
  font: normal 500 1.75rem/32px $font-family-base;
}

.text-h3 {
  //font size 24px
  font: normal 500 1.5rem/28px $font-family-base;
}

.text-h4 {
  //font size 20px
  font: normal 500 1.25rem/24px $font-family-base;
}

.text-h5 {
  //font size 18px
  font: normal 500 1.125rem/20px $font-family-base;
}

.text-large {
  //font size 16px
  font: normal 500 1rem/24px $font-family-base;
}

.text-medium {
  //font size 14px
  font: normal 500 0.875rem/20px $font-family-base;
}

.text-small {
  //font size 12px
  font: normal normal 0.75rem/18px $font-family-base;
}

.text-extra-small {
  //font size 10px
  font: normal normal 0.625rem/16px $font-family-base;
}

//icon rotate
.icon-rotate-90 {
  @include iconRotate(90deg);
}

.icon-rotate-180 {
  @include iconRotate(180deg);
}

.icon-rotate-273 {
  @include iconRotate(273deg);
}

.icon-rotate-240 {
  @include iconRotate(240deg);
}

.icon-rotate-360 {
  @include iconRotate(360deg);
}

.textalign {
  text-align: center;
}

.text-white {
  color: $white !important;
}

.text-nowrap {
  text-wrap: nowrap;
}

//height
.h-100 {
  height: 100%;
}

//width per clasess
@for $i from 0 through 100 {
  .w-#{$i} {
    width: round(percentage($i/100));
  }
}

@for $i from 0 through 100 {
  .mt-#{$i} {
    margin-top: #{($i/16)}rem !important;
  }
}

@for $i from 0 through 100 {
  .mr-#{$i} {
    margin-right: #{($i/16)}rem !important;
  }
}

@for $i from 0 through 100 {
  .ml-#{$i} {
    margin-left: #{($i/16)}rem !important;
  }
}

@for $i from 0 through 100 {
  .mb-#{$i} {
    margin-bottom: #{($i/16)}rem !important;
  }
}

@for $i from 0 through 100 {
  .p-#{$i} {
    padding: #{($i/16)}rem !important;
  }
}

@for $i from 0 through 100 {
  .pl-#{$i} {
    padding-left: #{($i/16)}rem !important;
  }
}

@for $i from 0 through 100 {
  .pr-#{$i} {
    padding-right: #{($i/16)}rem !important;
  }
}

@for $i from 0 through 100 {
  .pt-#{$i} {
    padding-top: #{($i/16)}rem !important;
  }
}

@for $i from 0 through 100 {
  .pb-#{$i} {
    padding-bottom: #{($i/16)}rem !important;
  }
}

@for $i from 0 through 100 {
  .border-radius-#{$i} {
    @include border-radius(#{$i}px);
  }
}

@for $i from 0 through 100 {
  .font-size-#{$i} {
    font-size: #{$i}px;
  }
}

.d-flex {
  display: flex;
}

.m-width {
  min-width: 350 !important;
}

.sidebarwrapper {
  position: fixed;
  left: 0;
  top: 0;
}

.baselayout {
  flex: 1;
  height: 100%;
}

.selectformcontrol {
  width: 100%;
}

.overflow_auto {
  overflow: auto;
}

.flex_1 {
  flex: 3;
}

.overflow_x_hidden {
  overflow-x: hidden;
}

.alignitem {
  align-items: center;
}

.loginBox {
  position: relative;

  &::after {
    height: 100%;
    content: '';
    position: absolute;
    width: 1px;
    right: 0px;
    background-color: $borderBgColor;
  }
}

.commonListScroll {
  @include commonScrollBar;
  overflow: auto;
}

//password strength meter
.passStrengthWeak {
  background-color: $progressErrorBg !important;

  .MuiLinearProgress-bar1Determinate {
    background-color: $progressError !important;
  }
}

.passStrengthFair {
  background-color: $progressWarningBg !important;

  .MuiLinearProgress-bar1Determinate {
    background-color: $progressWarning !important;
  }
}

.passStrengthGood {
  background-color: $progressStrongBg !important;

  .MuiLinearProgress-bar1Determinate {
    background-color: $progressStrong !important;
  }
}

.passStrengthStrong {
  background-color: $progressSuccessBg !important;

  .MuiLinearProgress-bar1Determinate {
    background-color: $progressSuccess !important;
  }
}

.errorState {
  color: $errorTextColor !important;
}

.letter-spacing {
  letter-spacing: 0.5px;
}

.customField {
  .MuiInputBase-root {
    .MuiInputBase-input {
      padding: 11px;
    }
  }
}

.MuiCard-root {
  border-radius: 0px !important;
}

//textFields
.textFields {
  .MuiTypography-body2 {
    color: $grayColor;
  }

  .MuiInputBase-root {
    @include border-radius(5px);

    .MuiInputBase-input {
      padding: 14px 14px !important;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: $inputFieldBorderColor;
    }
  }

  .MuiFormHelperText-root {
    font: normal 400 12px/14px $font-family-base;
    margin-left: 0px;
  }
}

// Empty State Widget
.emptyState {
  border: 1px dashed $primary;
  border-radius: 10px;
  background-color: $stateBackgroundColor;
  align-content: center;
  height: 11.063rem;
}

//userProfile
.userProfile {
  height: 48px;
  width: 48px;
  @include border-radius(8px);
  background-color: $stateBackgroundColor;
}

.plusIcon {
  font-size: 5.25rem;
  color: $primary;
}

.linkIcon {
  font-size: 1.5rem;
  color: $primary;
}

.activeStatusIcon {
  color: $activeStatusColor;
  font-size: 8px;
}

.inactiveStatusIcon {
  color: $inactiveStatusColor;
  font-size: 8px;
}

svg.social-svg {
  height: 2rem !important;
  width: 2rem !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin-top: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-left: auto;
}

.emptyStateMessages {
  color: $textMessageColor;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
}

.primaryColor {
  color: $primary !important;
}

.grayColor {
  color: $grayColor !important;
}

.grayColor200 {
  color: $grayColor200 !important;
}

.tertiaryColor {
  color: $tertiary !important;
}

.grayColor666 {
  color: $grayColor666 !important;
}

.userDefaultProfile {
  color: $user-default-profile;
}

.textColor {
  color: $textColor !important;
}

.candidateListItemsPrimaryColor {
  color: $candidateListItemsPrimaryColor !important;
}

.textMessageColor {
  color: $textMessageColor !important;
}

.pendingColor {
  color: $pendingColor !important;
}

.acceptedColor {
  color: $acceptedColor !important;
}

//fileUploads
.fileUploads {
  .icon-size-34 {
    font-size: 28px;
  }

  input {
    display: none;
  }

  .icon-delete {
    color: $grayComponentColor;
  }
}

//input select
.selectformcontrol {
  .MuiTypography-body2 {
    color: $grayColor;
  }

  .MuiInputBase-root {
    @include border-radius(4px);

    .MuiSelect-select {
      &.MuiSelect-outlined {
        padding: 13.5px 14px !important;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      top: 0px;
      border-color: $inputFieldBorderColor;

      legend {
        display: none;
      }
    }

    .MuiSvgIcon-root {
      &.MuiSelect-icon {
        font-size: 18px;
        font-weight: 600;
        top: calc(50% - 9px);
        color: #666b70;
        right: 12px;
      }
    }
  }

  .MuiFormHelperText-root {
    font: normal 400 12px/14px $font-family-base;
    margin-left: 0px;
  }
}

.tableHeadersFont {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.04em;
}

.detailScreenText {
  width: 400px;
}

.enableDisableDropDown {
  border-color: $primary;
  color: $primary;
}

.align-items-center {
  align-items: center !important;
}

.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: $primary;
}

.align-filter-close {
  right: -10px;
  top: -10px;
}

.filter-close {
  font-size: 22px;
  color: $white;
  margin-top: 1px;
}

.reverse-text {
  transform: scaleX(-1);
}

.width-120px {
  width: 120px;
}

.height-250px {
  height: 300px;
}

.underline {
  text-decoration: underline;
}

.jdFilterBackgroundColor {
  background-color: $white;
}

.tableEmailIcon {
  color: $primary;
  border: 2px solid rgba($primary, 0.14);
  background-color: #f4f6fc;
  padding: 2px;
  @include border-radius(4px);
  @include font-custom(900, 14px, normal);
}

.videoJoinIconBorder {
  border: 2px solid $primary;
  width: 35px;
  height: 35px;
  border-radius: 6px;
  cursor: pointer;
}

.videoJoinIconInTable {
  width: 25px;
  height: 13px;
  font-weight: bold;
  color: $primary;
}

.font16px {
  font-size: 16px;
}

.fieldTextColor {
  color: $resumeNameColor;
}

.uploadProfilePictureBtn {
  width: 204px;
}

.resumeIconColor,
.rejectedColor {
  color: rgb(253, 66, 51);
}

.multiselectformcontrol {
  .MuiTypography-body2 {
    color: $grayColor;
  }

  .MuiInputBase-root {
    @include border-radius(4px);

    &.MuiOutlinedInput-root {
      height: 50px;
      padding: 0px 10px;
    }
  }

  .MuiFormHelperText-root {
    font: normal 400 12px/14px $font-family-base;
    margin-left: 0px;
  }
}

.statusChipsInfoBgColor {
  color: $statusChipsInfoBgColor;
}

.closeIcon {
  font-size: 0.25rem;
  color: $statusChipsCloseIconColor;
}

.createJdSaveButton {
  width: 9.5rem;
  height: 2.75rem;
}

.max-width-view-screen {
  width: 300px;
}

.plusCountJdSkills {
  border-radius: 50%;
  width: 22px;
  height: 22px;
  background-color: $backgroundColor;
  align-content: center;
  cursor: pointer;
}

.fontSize12px {
  font-size: 12px !important;
  font-weight: 500 !important;
}

.ap {
  width: 500px;
  height: 50px;
}

.textEditor {
  margin-bottom: 40px;
}

.plusCountJdSkills {
  border-radius: 50%;
  width: 22px;
  height: 22px;
  background-color: $backgroundColor;
  align-content: center;
  cursor: pointer;
}

.fontSize12px {
  font-size: 12px !important;
  font-weight: 500 !important;
}

.ap {
  width: 500px;
  height: 50px;
}

.dialogHeight {
  font-size: small;
  color: $grayColor;
}

.blackColor {
  color: $black !important;
}

.text-editor-container {
  border-color: none;
}

.number-card {
  margin-top: 16px;
  width: 220px;
  height: 126px;
}
.dashboard-text-color {
  color: $dashboardTextColor;
}
.number-card-value-font-size {
  font-size: 40px;
}
.card-title-font-size {
  font-size: 15px;
}

.card-text {
  font-size: 12px;
}

.card-number-box {
  background-color: $cardNumberBoxColor;
  height: 23px;
  border-radius: 5px;
  padding: 0px 2px;
}

.rating-card {
  margin-top: 16px;
  width: 140px;
  height: 126px;
}
.rating-card-number-font {
  font-size: 30px;
}
.dashboard-table {
  width: 83.5rem;
  height: 22.313rem;
}
.dashboard-table .dashboard-active-option {
  font-weight: 600;
}
.interviews-text-dashboard {
  font-family: 'Poppins-Medium', sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: $dashboardTextColor;
}

// Schedule interview
.ratingSuccess {
  background: #07b892;
  border-radius: 20px;
  color: white;
  padding: 0.5px 8px;
}

.select-interviewer {
  .css-204u17-MuiDataGrid-main {
    height: 300px;
    margin-top: 5px;
  }
  .css-dten5e {
    display: none;
  }
  .css-1kks9nu-MuiPaper-root-MuiCard-root {
    border: none;
  }
  .filter-text-field {
    border: 1px solid #07b892;
  }
}
.select-interviewer-slot {
  .css-204u17-MuiDataGrid-main {
    height: 360px;
    margin-top: 5px;
  }
  .css-dten5e {
    display: none;
  }
  .css-1kks9nu-MuiPaper-root-MuiCard-root {
    border: none;
  }
}

.question-bank-actions-enabled {
  color: $primary;
  text-align: left;
  font-family: 'Poppins-Medium', sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  position: relative;
}
.question-bank-actions-disabled {
  color: $grayColor;
  text-align: left;
  font-family: 'Poppins-Medium', sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  position: relative;
}
.question-bank-actions-box-disabled {
  background: #ffffff;
  border-radius: 8px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.14);
  border-width: 1px;
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
  cursor: not-allowed;
}
.question-bank-actions-box-enabled {
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid $primary;
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
}
.question-bank-questions-box {
  background: $primary;
  border-radius: 8px;
  border: 1px solid $primary;
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
}
.question-bank-questions {
  color: $white;
  text-align: left;
  font-family: 'Poppins-Medium', sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  position: relative;
}
.question-bank-card {
  min-height: 21.25rem !important;
}
.template-profile-question-bank {
  border: 1px solid $grayColor;
  padding: 0.75rem;
  width: 19rem;
  height: 6rem;
}
.assgin-question-bank {
  border: 1px solid #dadada;
  padding: 0.75rem;
  width: 15rem;
  height: 5rem;
}
.assign-question-modal {
  @include commonScrollBar;
  overflow-y: scroll;
  height: 400px;
  padding: 5px;
}
.empty-templates {
  margin: 5.625rem;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.view-jd {
  font-size: 24px;
}

.jd-titleColor {
  color: $jd-titleColor;
}

.custom-dialogue {
  .MuiDialog-containe .css-9bwk84-MuiPaper-root-MuiDialog-paper {
    width: auto !important;
  }
  .resume-text {
    font-size: small;
  }
}

.resume-apply-btn-container {
  Button {
    vertical-align: top !important;
    padding: 4px 20px;
  }
}

.custom-document-viewer #pdf-controls {
  display: none;
}

.subheading-addCandidate {
  color: rgba(15, 18, 63, 0.7);
  font-size: 16px;
  line-height: 24px;
}

.url-subheading-addCandidate {
  color: rgba(15, 18, 63, 0.9);
}

.subheading-addCandidate {
  color: rgba(15, 18, 63, 0.7);
  font-size: 16px;
  line-height: 24px;
}

.url-subheading-addCandidate {
  color: rgba(15, 18, 63, 0.9);
}

.jd-hover-width {
  max-width: 29.875rem;
}

.width-60-percent {
  max-width: 60% !important;
}

.customDeleteModal-font {
  font-size: 25px;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.question-bank-checkbox {
  &.MuiCheckbox-root {
    border: 2px solid black;
    border-radius: 4px;
    color: black;
  }

  &.Mui-checked {
    color: black !important;
  }

  &.MuiCheckbox-indeterminate {
    color: black !important;
  }
}

.associated-parties-ellipsis {
  max-width: 90px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.candidate-card-box {
  width: 45%;
}

.candidate-status-dropdown {
  display: flex;
  justify-content: end;
  width: 100%;
}

.dashboard-graph {
  border: 1px solid #2e5bff24;
  border-radius: 10px;
  padding: 12px;
  background: #fff;
}
.bar-graph-container {
  width: 100% !important;
  height: 100% !important;
}
