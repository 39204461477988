//statusChips
.statusChips {
  &.MuiChip-root {
    font: normal 500 11px/16px $font-family-base;
    @include border-radius(6px);
    padding: 4px 20px;
    border: 1px solid;
    height: 28px;
    .MuiChip-label {
      padding: 0px;
      color: $statustextColor;
    }
    &.text-white {
      .MuiChip-label {
        color: $white;
      }
    }
    &.MuiChip-colorWarning {
      background-color: $statusChipsWarningBgColor;
      border-color: $statusChipsWarningBorderColor;
    }
    &.MuiChip-colorError {
      background-color: $statusChipsErrorBgColor;
      border-color: $statusChipsErrorBorderColor;
    }
    &.MuiChip-colorSuccess {
      background-color: $statusChipsSuccessBgColor;
      border-color: $statusChipsSuccessBorderColor;
    }
    &.MuiChip-colorInfo {
      background-color: $statusChipsInfoBgColor;
      border-color: $statusChipsInfoBorderColor;
    }
  }
}
