.eventCommonClass {
  background-color: $white;
  // @extend .p-relative;
  &:hover {
    .bottomControls {
      display: block !important;
    }
  }
  &.rbc-selected {
    &:active,
    &:focus {
      outline: none;
    }
  }
  .rbc-event-content {
    padding: 0;
    white-space: inherit;
    .eventBox {
      padding: 3px 0px;
      height: inherit;
      border-radius: 4px;
      &.published {
        background: rgba(229, 251, 242, 1);
        border: 2px dotted rgba(127, 213, 178, 1);
        .eventTitle {
          color: rgba(43, 148, 105, 1);
        }
      }
      &.recurring {
        background: rgba(227, 227, 227, 1);
        border: 2px dotted rgba(15, 18, 63, 0.5);
        .eventTitle {
          color: rgba(101, 103, 102, 1);
        }
      }
      .eventTitle {
        font: normal 600 14px/21px $font-family-base;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .eventDescritpion {
        height: 16px !important;
        min-height: 16px !important;
        font: normal 600 11px/15px $font-family-base;
        color: rgba(18, 20, 51, 0.9);
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .bottomControls {
        display: none;
        .eventActionBtn {
          padding: 6px 12px;
          @include border-radius(4px);
          border: 1px solid #e5e5ef;
          background: $white;
          box-shadow: 0px 4px 12px 0px rgba(13, 10, 44, 0.06);
          right: 12px;
          bottom: 1px;
          z-index: 300;
          height: 30px;
        }
      }
    }
  }
}

.calenderWrapper {
  height: 882px;
  width: 100%;
  .rbc-calendar {
    border-radius: 16px;
    border: 1px solid var(--color-surface-shade-03, #e9e9f8);
    background: #fff;
    box-shadow: 0px 12.5px 10.017px 0px rgba(25, 42, 75, 0.04);
    //calendar header
    .CalenderHeaderBar {
      border-radius: 12px 12px 0px 0px;
      background: #e9e9f8;
      min-height: 60px;
      .nextPrevControls {
        .btnBox {
          height: 32px;
          width: 32px;
          background-color: $white;
          border: 2px solid #ccc;
          @include border-radius(50%);
          .MuiSvgIcon-root {
            color: rgba(63, 65, 89, 0.8);
          }
        }
        .monthLabel {
          font: normal 600 14px/20px $font-family-base;
          color: $black;
          letter-spacing: 0.28px;
          text-transform: uppercase;
          display: flex;
          align-items: center;
        }
      }
    }

//     //month view
    .rbc-month-view {
      border: none;
      .rbc-month-header {
        .rbc-header {
          font: normal 700 14px/20px $font-family-base;
          color: #ccc;
          text-transform: uppercase;
          letter-spacing: 0.28px;
          padding: 8px 16px;
          text-align: right;
          border-left: none;
        }
      }
      .rbc-month-row {
        border-color: #ccc;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        overflow: visible;
        &:last-child {
          background-color: $white;
        }
        .rbc-row-bg {
          .rbc-day-bg {
            border-color: #ccc;
            background-color: transparent;
            &.rbc-today {
              background-color: transparent;
            }
            &.weekend-day {
              //to be change
              background-color: rgba(180, 181, 192, 0.1);
            }
          }
          .rbc-day-bg {
            &.rbc-off-range-bg {
              background-color: rgba(180, 181, 192, 0.1);
              color: #ccc;
            }
          }
        }
        .rbc-row-content {
          z-index: inherit;
          .rbc-date-cell {
            padding-right: 16px;
            padding-top: 10px;
            .rbc-button-link {
              font: normal 700 14px/18px $font-family-base;
              color: $primary;
            }
            &.rbc-current.rbc-now {
              .rbc-button-link {
                width: 28px;
                height: 28px;
                @include border-radius(50%);
                background-color: $primary;
                color: $white !important;
              }
            }
          }
          .rbc-off-range {
            .rbc-button-link {
              color: $calendarTextDisabled !important;
            }
          }
          //calendar event
          .rbc-row-segment {
            .rbc-event {
              @extend .eventCommonClass;
            }
            .rbc-button-link {
              &.rbc-show-more {
                font: normal 700 14px/20px $font-family-base;
                color: $primary;
                letter-spacing: 0.28px;
                padding-left: 8px;
              }
            }
          }
          .rbc-row {
            .rbc-date-cell {
              &:first-child {
                .rbc-button-link {
                  color: #ccc;
                }
              }
              &:last-child {
                .rbc-button-link {
                  color: #ccc;
                }
              }
            }
          }
        }
      }
    }
    //week and day view
    .rbc-time-view {
      border: none;
      .rbc-current-time-indicator {
        display: none !important;
      }
      .rbc-today {
        background-color: transparent !important; // TODO: imp this changes the whole column style
      }
      .rbc-time-header {
        .rbc-allday-cell {
          display: none;
        }
        .rbc-row-content {
          z-index: inherit;
        }
        &.rbc-overflowing {
          margin-right: 0px !important;
        }
        .rbc-time-header-content {
          .rbc-row.rbc-time-header-cell {
            min-height: 48px;
            .rbc-header {
              padding: 8px 16px;
              text-align: right;
              display: flex;
              justify-content: center;
              align-items: center;
              .rbc-button-link {
                font: normal 700 14px/20px $font-family-base;
                color: #ccc;
                text-transform: uppercase;
                letter-spacing: 0.28px;
              }
              &.rbc-today {
                background-color: $white !important;
              }
            }
          }
        }
        .rbc-time-header-cell-single-day {
          display: flex;
          .rbc-header {
            justify-content: start !important;
          }
        }
      }
      .rbc-time-content {
        .rbc-day-slot .rbc-time-slot {
          border-top: none !important;
        }

        .rbc-day-slot {
          color: darkgoldenrod;

          .rbc-timeslot-group {
            min-height: 50px;
          }
          .rbc-event {
            border: none;
//             top: 0px !important ; //changed currently
//             left: 0px !important;
//             width: 97% !important;
//             margin: 1% 1% 0% 2%;
            padding: 2px 3px;
            background-color: transparent;
            outline: none;
          }
          .rbc-event-label {
            display: none;
          }
          .rbc-events-container {
            border-left: none;
            margin: 0;
          }
        }
        border-top: 1px solid #ddd;
        .rbc-time-gutter.rbc-time-column {
          // display: none;
        }

        .weekend-day {
          .rbc-timeslot-group {
            &:first-child {
              border-left: none;
            }
          }
        }
        .rbc-day-slot.rbc-time-column.rbc-now.rbc-today {
          background-color: $white;
        }
      }
      .rbc-time-content {
        @include commonScrollBar;
        .rbc-time-gutter {
          .rbc-timeslot-group {
            min-height: 50px;
            border-bottom: none;
            .rbc-time-slot {
              .rbc-label {
                color: rgba(98, 107, 121, 1);
                font: normal 400 12ps/18px $font-family-base;
                letter-spacing: 0.02em;
              }
            }
          }
        }
      }
      .rbc-row-segment {
        .rbc-event {
          z-index: 1;
          @extend .eventCommonClass;
        }
      }
      .rbc-events-container {
        .rbc-event {
          z-index: 1;
          @extend .eventCommonClass;
          // height: 10% !important;
        }
        .rbc-current-time-indicator {
          display: none !important;
        }
        .rbc-day-slot .rbc-event {
          border: none !important;
        }
      }
    }
//     .rbc-day-slot .rbc-events-container {
//       height: 36%;
//       padding: 0px;
//       margin-right: 0px;
//     }
  }
}

//toggelButtonsStyle

.customToggleBtn.MuiToggleButtonGroup-root .MuiToggleButton-root {
  transition-delay: 3s;
  transition: background-color 2s ease-in;
}

// .customToggleBtn {
//   &.MuiToggleButtonGroup-root {
//     background-color: $white;
//     border: 2px solid #ccc;
//     @include border-radius(8px);
//     padding: 3px;
//     .MuiToggleButton-root {
//       font: normal 400 12px/18px $font-family-base;
//       color: $primary;
//       border: none;
//       @include border-radius(5px);
//       text-transform: capitalize;
//       background-color: transparent;
//       padding: 4px 16px;
//       &:hover {
//         font-weight: 700 !important;
//         color: $primary;
//       }
//       &.Mui-selected {
//         background-color: $bg-surface-color;
//         transition: background-color 3s ease-in-out;
//         background-color: $bg-surface-color;
//         color: $white;
//       }
//     }
//   }
// }
.rbc-calender {
  transition: all 0.3s ease-in-out;
}

.customWeekHeaderClass {
  padding: 5px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .MuiFormControlLabel-root {
    margin-left: 0px;
    margin-right: 0px;
    .MuiFormControlLabel-label {
      font: normal 5600 15px/20px $font-family-base;
      letter-spacing: 0.02em;
      text-transform: capitalize;
      color: $calendarTextColor;
    }
  }
  .dayLabel {
    font: normal 500 12px/20px $font-family-base;
    letter-spacing: 0.02em;
    color: $calendarTextColor;
  }
  .dayLabelVal {
    font: normal 600 16px/20px $font-family-base;
    letter-spacing: 0.02em;
    color: $calendarTextColor;
    &.weekHeaderClassCurrentDate {
      background-color: $primary;
      color: $white;
      border-radius: 25px;
      height: 26px;
      width: 26px;
      @extend .flex-basic-center;
    }
  }
}
