//customDatePicker

.customDatePicker {
  .MuiFormControl-root.MuiTextField-root {
    @extend .w-100;
    .MuiInputLabel-root {
      display: none;
    }
    .MuiInputBase-root {
      @include border-radius(4px);
      .MuiInputBase-input {
        padding: 14px 14px !important;
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: $inputFieldBorderColor;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      top: 0px;
      legend {
        display: none;
      }
    }
  }
  .datePickerLabel {
    color: $grayColor;
    font: normal 400 14px/16px $font-family-base;
  }
  .MuiInputAdornment-positionEnd {
    .MuiButtonBase-root {
      position: relative;
      margin-right: -10px;
      &:hover {
        background-color: transparent;
      }
      padding: 0px;
      &::after {
        font-size: 18px;
        content: '\e906';
        font-family: $font-family-icomoon;
        position: absolute;
        color: #666b70;
      }
      .MuiSvgIcon-root {
        visibility: hidden;
      }
    }
  }
}
