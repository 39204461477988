.profilePicture {
  display: flex;
  flex-direction: column;
  align-items: center;
  .profilePic {
    background-color: $stateBackgroundColor;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include border-radius(9px);
    }
    .icon-users {
      font-size: 48px;
      color: #ccc;
    }
  }
  .uploadPicBtn {
    input {
      display: none;
    }
  }
}
