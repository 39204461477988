.customTabs {
  .MuiBox-root {
    .MuiTabs-root {
      overflow: hidden !important;
      .MuiTabs-scroller {
        overflow: hidden !important;

        .MuiTab-root {
          position: relative;
          .MuiTabs-scroller {
            overflow-y: hidden !important;
          }
          @include border-radius(0px);
          &.Mui-selected {
            &:after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 4px;
              background-color: $primary;
              border-radius: 99px 99px 0px 0px;
            }
          }
        }
      }
    }
  }
}
.tabPanel {
  div[role='tabpanel'] {
    background-color: transparent !important;
  }
}
